import React from 'react'
import { history } from 'umi'
import { bindLifecycle } from '@paas/react-keep-alive'

@bindLifecycle
export default class KeepAliveWrapper extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return <div>{this.props.children}</div>
  }
}
