import React from 'react'
import { history } from 'umi'
import { KeepAlive } from '@paas/react-keep-alive'

import BindLifecycle from './bindLifecycle'

export default class KeepAliveWrapper extends React.Component {
  render() {
    return (
      <KeepAlive name={history.location.pathname}>
        <BindLifecycle>{this.props.children}</BindLifecycle>
      </KeepAlive>
    )
  }
}
