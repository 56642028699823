import memoizeOne from 'memoize-one'
import { isEqual } from 'lodash-es'
import { useState, useCallback } from 'react'
import { message } from 'antd'
import Cookies from 'js-cookie'
import request from '@/Layout/Login/request'
import { REPORT_URI } from '@/kit/env_config'
import { LOGOUT_URI } from '@/kit/env_config'
import { IconType } from 'antd/lib/notification'
import { event as GlobalEvent } from '@/kit/Global'
const ipRegEx = /\d{0,3}\.\d{0,3}\.\d{0,3}\.\d{0,3}/g

export const logoutUrl = () => LOGOUT_URI[window.__NEWDZ__.env.PROD_ENV]
export const reportUrl = () => REPORT_URI[window.__NEWDZ__.env.PROD_ENV]
export const isEdu = () => window.__NEWDZ__.env.PROD_ENV.includes('edu')

const reg = /(((^https?:(?:\/\/)?)(?:[-;:&=\\+\\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\\+\\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\\+~%\\/.\w-_]*)?\??(?:[-\\+=&;%@.\w_]*)#?(?:[\w]*))?)$/

export function isUrl(path: string): boolean {
  return reg.test(path)
}

// eslint-disable-next-line max-params
export const array2Tree = memoizeOne((arr, primaryKey, parentKey, primaryValue) => {
  const getNode = (id) => {
    const tree = []
    for (const item of arr) {
      if (item[parentKey] === id) {
        item.children = getNode(item[primaryKey])
        tree.push(item)
      }
    }
    return tree
  }

  return getNode(primaryValue)
}, isEqual)

export type CommonTree = {
  parentId?: number
  treeId?: number
  treeName?: string
  checked?: boolean
  childList?: CommonTree[]
  [k: string]: unknown
}

export type DataNode = {
  checkable?: boolean
  children?: DataNode[]
  disabled?: boolean
  disableCheckbox?: boolean
  icon?: IconType
  isLeaf?: boolean
  parentId?: string
  key: string | number
  title?: React.ReactNode
  selectable?: boolean
  switcherIcon?: IconType
  /** Set style of TreeNode. This is not recommend if you don't have any force requirement */
  className?: string
  style?: React.CSSProperties
}

export interface EventDataNode extends DataNode {
  expanded: boolean
  selected: boolean
  checked: boolean
  loaded: boolean
  loading: boolean
  halfChecked: boolean
  dragOver: boolean
  dragOverGapTop: boolean
  dragOverGapBottom: boolean
  pos: string
  active: boolean
}

export type TreeNodeData = CommonTree & {
  title?: React.ReactNode
  key?: string | number
  children?: TreeNodeData[]
}

export function traverseTree(
  tree?: CommonTree[],
  checkedKeys: Set<string | number> = new Set()
  // data: { key: string | number; title: string }[] = []
): [DataNode[], (string | number)[]] {
  const res = tree?.reduce((acc, cur) => {
    if (cur.checked) {
      checkedKeys.add(cur.treeId)
    } else {
      checkedKeys.delete(cur.parentId)
    }
    // data.push({ key: cur.treeId, title: cur.treeName })
    acc.push({
      title: cur.treeName,
      key: cur.treeId,
      parentId: String(cur.parentId),
      children: traverseTree(cur.childList, checkedKeys)[0]
    })
    return acc
  }, [] as DataNode[])
  return [res, [...checkedKeys]]
}

export function getItemsByIndexs(
  tree: TreeNodeData[],
  indexs: number[],
  data = []
): TreeNodeData[] {
  const tempIndexs = [...indexs]
  let tempTree = tree
  while (tempIndexs.length > 0) {
    const index = tempIndexs.shift()
    data.push(tempTree[index].treeId)
    tempTree = tempTree[index].children
  }
  return data.filter((a) => a !== undefined)
}

export function useForceUpdate(): () => void {
  const [, setTick] = useState(0)
  const update = useCallback(() => {
    setTick((tick) => tick + 1)
  }, [])
  return update
}

/**
 * 导出文件下载
 * @param url 文件路径url
 * @param fileName 文件名称
 */
export const fileDownload = (url: string, fileName?: string, pdf = false): void => {
  const filename = fileName || 'file'
  // 创建隐藏<a>标签进行下载
  const tempLink = document.createElement('a')
  tempLink.style.display = 'none'
  tempLink.href = url
  tempLink.setAttribute('download', filename)
  if (typeof tempLink.download === 'undefined' || pdf) {
    tempLink.setAttribute('target', '_blank')
  }
  document.body.appendChild(tempLink)
  tempLink.click()
  document.body.removeChild(tempLink)
  window.URL.revokeObjectURL(url)
  message.success('导出成功')
}
GlobalEvent.on('fileDownload', fileDownload)

export function keysOf<T>(val: T): (keyof T)[] {
  return Object.keys(val) as (keyof T)[]
}

export const commonIframeProps = {
  width: '100%',
  height: '100%',
  frameBorder: '0'
}

export type ListWrapper<T> = {
  data: T[]
  page: number
  size: number
  total: number
}

export const ALL = Symbol('all')

export function menuTreeToArray(menuTree: any[], menuArray: any[]) {
  for (const item of menuTree) {
    if (item.childList) {
      menuTreeToArray(item.childList, menuArray)
    }
    const newItem = {
      ...item,
      childList: null
    }
    menuArray.push(newItem)
  }
}

/**
 *  登录接口获取Cookies
 * @param param 登录接口返回值
 */
export const setCookies = (param) => {
  Cookies.set('access_token', param.access_token ?? '', {
    expires: 7
  })
  Cookies.set('refresh_token', param.refresh_token ?? '', {
    expires: 7
  })
}

/**
 *  登录接口获取Cookies
 * @param param 登录接口返回值
 */
export async function logout() {
  let domains = []
  if (ipRegEx.test(window.location.hostname)) {
    domains.push(window.location.hostname)
  } else {
    domains.push(window.location.hostname)
    let hostList = window.location.hostname.split('.')
    if (hostList.length > 1) {
      domains.push(hostList.slice(1).join('.'))
    }
  }

  await request.post('/facade/user/unauth/logout', {
    data: {
      domains
    }
  })
  setCookies({
    access_token: '',
    refresh_token: ''
  })

  window.location.replace(logoutUrl())
}

type HashUrl = string

export function getHashParam<T>(search: HashUrl): T {
  let entrys = search.replace(/.*\?/, '').split('&')

  let result: any = {}

  entrys.forEach((item) => {
    let [key, value] = item.split('=')
    result[key] = decodeURIComponent(value)
  })
  return result
}
/*
 * 参数说明：
 * number：要格式化的数字
 * decimals：保留几位小数
 * dec_point：小数点符号
 * thousands_sep：千分位符号
 * */
export function amountFormat(number, decimals, dec_point, thousands_sep) {
  number = (number + '').replace(/[^0-9+-Ee.]/g, '')
  let n = !isFinite(+number) ? 0 : +number,
    prec = !isFinite(+decimals) ? 2 : Math.abs(decimals),
    sep = typeof thousands_sep === 'undefined' ? ',' : thousands_sep,
    dec = typeof dec_point === 'undefined' ? '.' : dec_point,
    s = '',
    toFixedFix = function (n, prec) {
      var k = Math.pow(10, prec)
      return '' + Math.ceil(n * k) / k
    }

  s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.')
  var re = /(-?\d+)(\d{3})/
  while (re.test(s[0])) {
    s[0] = s[0].replace(re, '$1' + sep + '$2')
  }

  if ((s[1] || '').length < prec) {
    s[1] = s[1] || ''
    s[1] += new Array(prec - s[1].length + 1).join('0')
  }
  return s.join(dec)
}
