import { Plugin } from '/home/jenkins/agent/workspace/pipeline_p-4sxpd-7/code/packages/authority/node_modules/@umijs/runtime';

const plugin = new Plugin({
  validKeys: ['patchRoutes','rootContainer','render','onRouteChange','getInitialState','request',],
});
plugin.register({
  apply: require('/home/jenkins/agent/workspace/pipeline_p-4sxpd-7/code/packages/authority/src/app.ts'),
  path: '/home/jenkins/agent/workspace/pipeline_p-4sxpd-7/code/packages/authority/src/app.ts',
});
plugin.register({
  apply: require('../plugin-initial-state/runtime'),
  path: '../plugin-initial-state/runtime',
});
plugin.register({
  apply: require('../plugin-model/runtime'),
  path: '../plugin-model/runtime',
});

export { plugin };
