/**
 * 根据不同的打包参数PROD_ENV设置页面上不同地方调用的后端的地址
 */

export const REPORT_URI = {
  PRO: 'https://report.yunzhangfang.com/rm/output/report/',
  DEV: 'http://report.yunzhangfang.com:8080/rm/output/report/',
  FAT: 'http://report.yunzhangfang.com:8080/rm/output/report/',
  'edu-prod': 'https://report.yunzhangfang.com/rm/output/report/',
  'edu-dev': 'https://report.yunzhangfang.com/rm/output/report/',
  PRE: 'http://report.yunzhangfang.com/yfb_web/output/report/'
}

export const LOGOUT_URI = {
  PRO: 'https://sso.yunzhangfang.com/login.html?url=https://daizhang99.yunzhangfang.com/loading',
  DEV: '/login',
  FAT: '/login',
  EDU: '/login',
  PRE: '/login'
}
