import { ApplyPluginsType, dynamic } from '/home/jenkins/agent/workspace/pipeline_p-4sxpd-7/code/packages/authority/node_modules/@umijs/runtime';
import { plugin } from './plugin';

const routes = [
  {
    "path": "/login",
    "title": "登录",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'Layout__Login' */'@/Layout/Login'), loading: require('@/components/Loading').default}),
    "routes": [
      {
        "path": "/login/index",
        "title": "登录",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'Layout__Login__components__LoginIndex' */'@/Layout/Login/components/LoginIndex'), loading: require('@/components/Loading').default}),
        "exact": true
      },
      {
        "path": "/login/otherRelated",
        "title": "关联账号",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'Layout__Login__otherRelated' */'@/Layout/Login/otherRelated'), loading: require('@/components/Loading').default}),
        "exact": true
      },
      {
        "path": "/login/otherLogin",
        "title": "登录关联账号",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'Layout__Login__otherLogin' */'@/Layout/Login/otherLogin'), loading: require('@/components/Loading').default}),
        "exact": true
      },
      {
        "path": "/login/retrieveCode",
        "title": "找回密码",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'Layout__Login__retrieveCode' */'@/Layout/Login/retrieveCode'), loading: require('@/components/Loading').default}),
        "exact": true
      },
      {
        "exact": true,
        "path": "/login*",
        "redirect": "/login/index"
      }
    ]
  },
  {
    "path": "/loading",
    "title": "Loading",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'components__Loading' */'@/components/Loading'), loading: require('@/components/Loading').default}),
    "exact": true
  },
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'Layout__index' */'@/Layout/index'), loading: require('@/components/Loading').default}),
    "routes": [
      {
        "path": "/home",
        "title": "新代帐首页",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'Layout__HomeLayout' */'@/Layout/HomeLayout'), loading: require('@/components/Loading').default}),
        "routes": [
          {
            "path": "/home/auth",
            "title": "权限管理系统",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'Layout__AuthLayout' */'@/Layout/AuthLayout'), loading: require('@/components/Loading').default}),
            "routes": [
              {
                "path": "/home/auth/accountAdmin",
                "title": "账号管理",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__auth__account_admin' */'@/pages/home/auth/account_admin'), loading: require('@/components/Loading').default}),
                "exact": true
              },
              {
                "path": "/home/auth/companyAccount",
                "title": "企业账号绑定",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__auth__company_account' */'@/pages/home/auth/company_account'), loading: require('@/components/Loading').default}),
                "exact": true
              },
              {
                "path": "/home/auth/departStaff",
                "title": "部门人员",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__auth__depart_staff' */'@/pages/home/auth/depart_staff'), loading: require('@/components/Loading').default}),
                "exact": true
              },
              {
                "path": "/home/auth/roleauth",
                "title": "角色管理",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__auth__role_auth' */'@/pages/home/auth/role_auth'), loading: require('@/components/Loading').default}),
                "exact": true
              },
              {
                "path": "/home/auth/staffAdmin",
                "title": "人员管理",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__auth__staff_admin' */'@/pages/home/auth/staff_admin'), loading: require('@/components/Loading').default}),
                "exact": true
              },
              {
                "path": "/home/auth/customerauth",
                "title": "客户授权",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__auth__customer_auth' */'@/pages/home/auth/customer_auth'), loading: require('@/components/Loading').default}),
                "exact": true
              },
              {
                "exact": true,
                "path": "/home*",
                "redirect": "/home/homepage"
              }
            ]
          },
          {
            "path": "/home/userinfo",
            "title": "客户信息",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__user_info' */'@/pages/home/user_info'), loading: require('@/components/Loading').default}),
            "exact": true
          },
          {
            "path": "/home/charge",
            "title": "收费管理",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__charge' */'@/pages/home/charge'), loading: require('@/components/Loading').default}),
            "exact": true
          },
          {
            "path": "/home/persontax",
            "title": "个税初始化",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__person_tax' */'@/pages/home/person_tax'), loading: require('@/components/Loading').default}),
            "exact": true
          },
          {
            "path": "/home/homepage",
            "title": "首页",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__home_page' */'@/pages/home/home_page'), loading: require('@/components/Loading').default}),
            "exact": true
          },
          {
            "path": "/home/taxinit",
            "title": "税务初始化",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__tax_init' */'@/pages/home/tax_init'), loading: require('@/components/Loading').default}),
            "exact": true
          },
          {
            "path": "/home/financialinit",
            "title": "财务初始化",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__financial_init__old' */'@/pages/home/financial_init/old.tsx'), loading: require('@/components/Loading').default}),
            "exact": true
          },
          {
            "path": "/home/batchtax",
            "title": "批量申报",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'Layout__BatchTaxLayout' */'@/Layout/BatchTaxLayout'), loading: require('@/components/Loading').default}),
            "routes": [
              {
                "path": "/home/batchtax/total",
                "title": "总览",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__batchtax__pages__total' */'@/pages/home/batchtax/pages/total'), loading: require('@/components/Loading').default}),
                "exact": true
              },
              {
                "path": "/home/batchtax/declaretax",
                "title": "税款申报",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__batchtax__pages__declare__shenbao' */'@/pages/home/batchtax/pages/declare/shenbao'), loading: require('@/components/Loading').default}),
                "exact": true
              },
              {
                "path": "/home/batchtax/zero",
                "title": "零申报",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__batchtax__pages__declare__zero' */'@/pages/home/batchtax/pages/declare/zero'), loading: require('@/components/Loading').default}),
                "exact": true
              },
              {
                "path": "/home/batchtax/pay",
                "title": "缴税",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__batchtax__pages__koukuan' */'@/pages/home/batchtax/pages/koukuan'), loading: require('@/components/Loading').default}),
                "exact": true
              },
              {
                "path": "/home/batchtax/check",
                "title": "漏报检查",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__batchtax__pages__check' */'@/pages/home/batchtax/pages/check'), loading: require('@/components/Loading').default}),
                "exact": true
              },
              {
                "path": "/home/batchtax/setting",
                "title": "系统设置",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__batchtax__pages__setting' */'@/pages/home/batchtax/pages/setting'), loading: require('@/components/Loading').default}),
                "exact": true
              }
            ]
          },
          {
            "path": "/home/batchcheckout",
            "title": "批量结账",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__batch_checkout' */'@/pages/home/batch_checkout'), loading: require('@/components/Loading').default}),
            "exact": true
          },
          {
            "path": "/home/digitalledger",
            "title": "电子账簿",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__digital_ledger' */'@/pages/home/digital_ledger'), loading: require('@/components/Loading').default}),
            "exact": true
          },
          {
            "path": "/home/collect",
            "title": "数据采集",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'Layout__CollectLayout' */'@/Layout/CollectLayout'), loading: require('@/components/Loading').default}),
            "routes": [
              {
                "path": "/home/collect/invoice/check",
                "title": "勾选认证",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__collect__invoice__check' */'@/pages/home/collect/invoice/check'), loading: require('@/components/Loading').default}),
                "exact": true
              },
              {
                "path": "/home/collect/invoice/execute",
                "title": "发票采集",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__collect__invoice__execute' */'@/pages/home/collect/invoice/execute'), loading: require('@/components/Loading').default}),
                "exact": true
              },
              {
                "path": "/home/collect/invoice/manage",
                "title": "托管企业",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__collect__invoice__manage' */'@/pages/home/collect/invoice/manage'), loading: require('@/components/Loading').default}),
                "exact": true
              },
              {
                "path": "/home/collect/invoice/total",
                "title": "全部企业",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__collect__invoice__total' */'@/pages/home/collect/invoice/total'), loading: require('@/components/Loading').default}),
                "exact": true
              },
              {
                "path": "/home/collect/security",
                "title": "社保采集",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__collect__security' */'@/pages/home/collect/security'), loading: require('@/components/Loading').default}),
                "exact": true
              },
              {
                "path": "/home/collect/setting",
                "title": "采集设置",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__collect__setting' */'@/pages/home/collect/setting'), loading: require('@/components/Loading').default}),
                "exact": true
              }
            ]
          },
          {
            "exact": true,
            "path": "/home*",
            "redirect": "/home/homepage"
          }
        ]
      },
      {
        "path": "/ledger",
        "title": "新代帐账簿",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'Layout__CompanyLayout' */'@/Layout/CompanyLayout'), loading: require('@/components/Loading').default}),
        "routes": [
          {
            "path": "/ledger/index",
            "title": "首页",
            "wrappers": [require('@/Wrappers/KeepAliveWrapper').default],
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__company__accountBook' */'@/pages/company/accountBook'), loading: require('@/components/Loading').default}),
            "exact": true
          },
          {
            "path": "/ledger/income/add",
            "title": "新增进项发票",
            "wrappers": [require('@/Wrappers/KeepAliveWrapper').default],
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__company__invoice__input__add' */'@/pages/company/invoice/input/add'), loading: require('@/components/Loading').default}),
            "exact": true
          },
          {
            "path": "/ledger/outcome/add",
            "title": "新增销项发票",
            "wrappers": [require('@/Wrappers/KeepAliveWrapper').default],
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__company__invoice__input__add' */'@/pages/company/invoice/input/add'), loading: require('@/components/Loading').default}),
            "exact": true
          },
          {
            "path": "/ledger/income/edit",
            "title": "编辑进项发票",
            "wrappers": [require('@/Wrappers/KeepAliveWrapper').default],
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__company__invoice__input__add' */'@/pages/company/invoice/input/add'), loading: require('@/components/Loading').default}),
            "exact": true
          },
          {
            "path": "/ledger/outcome/edit",
            "title": "编辑销项发票",
            "wrappers": [require('@/Wrappers/KeepAliveWrapper').default],
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__company__invoice__input__add' */'@/pages/company/invoice/input/add'), loading: require('@/components/Loading').default}),
            "exact": true
          },
          {
            "path": "/ledger/basicsetting/taxinit",
            "title": "初始税务数据",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__company__basic_setting__taxation_init' */'@/pages/company/basic_setting/taxation_init'), loading: require('@/components/Loading').default}),
            "exact": true
          },
          {
            "path": "/ledger/tjbb/swbb",
            "wrappers": [require('@/Wrappers/KeepAliveWrapper').default],
            "title": "税务报表",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__company__report__tax__' */'@/pages/company/report/tax/'), loading: require('@/components/Loading').default}),
            "exact": true
          },
          {
            "path": "/ledger/tjbb/detail",
            "wrappers": [require('@/Wrappers/KeepAliveWrapper').default],
            "title": "税务报表编辑",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__company__report__tax__baobiao' */'@/pages/company/report/tax/baobiao'), loading: require('@/components/Loading').default}),
            "exact": true
          },
          {
            "path": "/ledger/zckp/card",
            "title": "资产卡片",
            "wrappers": [require('@/Wrappers/KeepAliveWrapper').default],
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__company__capital__capital_assets__' */'@/pages/company/capital/capital_assets/'), loading: require('@/components/Loading').default}),
            "exact": true
          },
          {
            "path": "/ledger/zckp/depreciation",
            "title": "折旧摊销",
            "wrappers": [require('@/Wrappers/KeepAliveWrapper').default],
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__company__capital__capital_depreciation__' */'@/pages/company/capital/capital_depreciation/'), loading: require('@/components/Loading').default}),
            "exact": true
          },
          {
            "path": "/ledger/zckp/change",
            "title": "资产变动",
            "wrappers": [require('@/Wrappers/KeepAliveWrapper').default],
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__company__capital__capital_change__' */'@/pages/company/capital/capital_change/'), loading: require('@/components/Loading').default}),
            "exact": true
          },
          {
            "path": "/ledger/zckp/add",
            "title": "新增资产",
            "wrappers": [require('@/Wrappers/KeepAliveWrapper').default],
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__company__capital__capital_assets__components__FormBlock' */'@/pages/company/capital/capital_assets/components/FormBlock'), loading: require('@/components/Loading').default}),
            "exact": true
          },
          {
            "path": "/ledger/zckp/edit",
            "title": "编辑资产",
            "wrappers": [require('@/Wrappers/KeepAliveWrapper').default],
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__company__capital__capital_assets__components__FormBlock' */'@/pages/company/capital/capital_assets/components/FormBlock'), loading: require('@/components/Loading').default}),
            "exact": true
          },
          {
            "path": "/ledger/companyInfo",
            "title": "企业信息",
            "wrappers": [require('@/Wrappers/KeepAliveWrapper').default],
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__user_info__companyInfo' */'@/pages/home/user_info/companyInfo'), loading: require('@/components/Loading').default}),
            "exact": true
          },
          {
            "path": "/ledger/accountReport",
            "title": "会计报表",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__company__accountReport' */'@/pages/company/accountReport'), loading: require('@/components/Loading').default}),
            "exact": true
          },
          {
            "path": "/ledger/auxiliaryAccounting",
            "title": "辅助核算",
            "wrappers": [require('@/Wrappers/KeepAliveWrapper').default],
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__company__auxiliaryAccounting' */'@/pages/company/auxiliaryAccounting'), loading: require('@/components/Loading').default}),
            "exact": true
          },
          {
            "path": "/ledger/habitsetting/pzprint",
            "title": "凭证打印模板",
            "wrappers": [require('@/Wrappers/KeepAliveWrapper').default],
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__company__habit__pingz_print' */'@/pages/company/habit/pingz_print'), loading: require('@/components/Loading').default}),
            "exact": true
          },
          {
            "path": "/ledger/supplementaryAccounting/balances",
            "title": "核算项目余额表",
            "wrappers": [require('@/Wrappers/KeepAliveWrapper').default],
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__company__supplementaryAccounting__balances' */'@/pages/company/supplementaryAccounting/balances'), loading: require('@/components/Loading').default}),
            "exact": true
          },
          {
            "path": "/ledger/financial/kmye",
            "title": "科目余额表",
            "wrappers": [require('@/Wrappers/KeepAliveWrapper').default],
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__company__financial_tables__pages__kmye' */'@/pages/company/financial_tables/pages/kmye'), loading: require('@/components/Loading').default}),
            "exact": true
          },
          {
            "exact": true,
            "path": "/ledger*",
            "redirect": "/ledger/index"
          }
        ]
      },
      {
        "exact": true,
        "path": "/*",
        "redirect": "/home/homepage"
      }
    ]
  },
  {
    "exact": true,
    "path": "/*",
    "redirect": "/home/homepage"
  }
];

// allow user to extend routes
plugin.applyPlugins({
  key: 'patchRoutes',
  type: ApplyPluginsType.event,
  args: { routes },
});

export { routes };
