/**
 * Base on umi-request
 * See https://github.com/umijs/umi-request
 */
import { extend } from 'umi-request'

const request = extend({
  timeout: 30000
})

request.interceptors.request.use(
  (url, options) => {
    const { headers } = options
    headers['content-type'] = 'application/json; charset=utf-8'
    headers['access-control-allow-origin'] = '*'
    headers['crossdomain'] = true
    headers['Authorization'] = 'bearer' + ((window as any).token ? ' ' + (window as any).token : '')
    return {
      url,
      options: {
        ...options,
        headers
      }
    }
  },
  { global: false }
)

request.interceptors.response.use(
  async (response) => {
    return response
  },
  { global: false }
)

export default request
