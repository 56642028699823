import moment from 'moment'
import 'moment/locale/zh-cn'
import { event as GlobalEvent } from '@/kit/Global'
import api from '@/api'

moment.locale('zh-cn')

// 财务初始化
localStorage.setItem('cwInitialize', 'false')

//提过的统一的校验按钮权限的方法
function validatorAuthCode(btnCode) {
  let authList = JSON.parse(localStorage.getItem('userMenu')) || []
  let authCodeList = authList.map((item) => {
    return item.code
  })
  let res = authCodeList.includes(btnCode)
  return res
}
GlobalEvent.on('validatorAuthCode', validatorAuthCode)
