import React from 'react'
import { Spin } from 'antd'
import { useLocation, useHistory } from 'umi'
import { setCookies } from '@/utils/utils'

export default () => {
  const location = useLocation()
  const history = useHistory()
  // 统计登录接口，如果是外部带token跳进来带存token跳转至首页
  if (location.search.includes('access_token')) {
    setCookies({
      // @ts-ignore
      access_token: location.query.access_token ?? '',
      // @ts-ignore
      refresh_token: location.query.refresh_token ?? ''
    })
    history.push('/home')
  }
  return (
    <Spin
      style={{
        display: 'flex',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      size='large'
    />
  )
}
